<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Question" vid="question" rules="required" v-slot="{ errors }">
              <b-form-group
                class="row"
                label-cols-sm="12"
                id="question"
                label-for="question"
              >
              <template v-slot:label>
                Question <span>*</span>
              </template>
                <b-form-input
                  id="question"
                  v-model="formFiled.question"
                  placeholder="Enter Question"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="pt-3 px-2">
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Answer" vid="answer" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="remarks"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Answer<span class="text-danger">*</span>
                </template>
                <b-textarea
                  id="answer"
                  v-model="formFiled.answer"
                  :state="errors[0] ? false : (valid ? true : null)"
                  rows="3"
                  ></b-textarea>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
      </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
        <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
        </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      formFiled: {
        question: '',
        answer: ''
      },
      errors: [],
      valid: null,
      loading: false,
      parentList: []
    }
  },
  created () {
    if (this.editItem) {
      this.formFiled = this.editItem
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.formFiled.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_faq_data', this.formFiled)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_faq_data', this.formFiled)
      }
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
